<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuer Veranstalter">
      <support-organization-form
        :organization="organization"
        submit-label="Veranstalter anlegen"
        :form-submitted="saveSupportOrganization"
        mode="new"
      ></support-organization-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import SupportOrganizationForm from "@/components/forms/SupportOrganizationForm"
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"

export default {
  metaInfo: {
    title: "Neuer Veranstalter"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    SupportOrganizationForm
  },
  data() {
    return {
      organization: {}
    }
  },
  methods: {
    async saveSupportOrganization(organization) {
      await SupportOrganizationsRepository.create(organization)
      this.$router.push({ name: "SupportOrganizationsPage" })
    }
  }
}
</script>
